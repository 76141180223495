/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { acceptInvite } from '../fn/users/accept-invite';
import { AcceptInvite$Params } from '../fn/users/accept-invite';
import { accountInfo } from '../fn/users/account-info';
import { AccountInfo$Params } from '../fn/users/account-info';
import { AccountInfoResponse } from '../models/account-info-response';
import { addOwner } from '../fn/users/add-owner';
import { AddOwner$Params } from '../fn/users/add-owner';
import { AddOwnerResponse } from '../models/add-owner-response';
import { assignRole } from '../fn/users/assign-role';
import { AssignRole$Params } from '../fn/users/assign-role';
import { changePassword } from '../fn/users/change-password';
import { ChangePassword$Params } from '../fn/users/change-password';
import { changeRole } from '../fn/users/change-role';
import { ChangeRole$Params } from '../fn/users/change-role';
import { createUser } from '../fn/users/create-user';
import { CreateUser$Params } from '../fn/users/create-user';
import { deleteSessionMembership } from '../fn/users/delete-session-membership';
import { DeleteSessionMembership$Params } from '../fn/users/delete-session-membership';
import { deleteUser } from '../fn/users/delete-user';
import { DeleteUser$Params } from '../fn/users/delete-user';
import { getAccountInfo } from '../fn/users/get-account-info';
import { GetAccountInfo$Params } from '../fn/users/get-account-info';
import { GetAllUsersTermsConditionsResponse } from '../models/get-all-users-terms-conditions-response';
import { getSessionAccountInfo } from '../fn/users/get-session-account-info';
import { GetSessionAccountInfo$Params } from '../fn/users/get-session-account-info';
import { getTermsAndConditions } from '../fn/users/get-terms-and-conditions';
import { GetTermsAndConditions$Params } from '../fn/users/get-terms-and-conditions';
import { getUserAccessibleApps } from '../fn/users/get-user-accessible-apps';
import { GetUserAccessibleApps$Params } from '../fn/users/get-user-accessible-apps';
import { getUserApplicationPreferences } from '../fn/users/get-user-application-preferences';
import { GetUserApplicationPreferences$Params } from '../fn/users/get-user-application-preferences';
import { getUsers } from '../fn/users/get-users';
import { GetUsers$Params } from '../fn/users/get-users';
import { inviteUser } from '../fn/users/invite-user';
import { InviteUser$Params } from '../fn/users/invite-user';
import { LeaveOrganizationResponse } from '../models/leave-organization-response';
import { rejectInvite } from '../fn/users/reject-invite';
import { RejectInvite$Params } from '../fn/users/reject-invite';
import { resendInvite } from '../fn/users/resend-invite';
import { ResendInvite$Params } from '../fn/users/resend-invite';
import { revokeAccess } from '../fn/users/revoke-access';
import { RevokeAccess$Params } from '../fn/users/revoke-access';
import { revokeInvite } from '../fn/users/revoke-invite';
import { RevokeInvite$Params } from '../fn/users/revoke-invite';
import { roleCount } from '../fn/users/role-count';
import { RoleCount$Params } from '../fn/users/role-count';
import { UpdateAccountInfoResponse } from '../models/update-account-info-response';
import { updateSessionAccountInfo } from '../fn/users/update-session-account-info';
import { UpdateSessionAccountInfo$Params } from '../fn/users/update-session-account-info';
import { updateTermsAndConditions } from '../fn/users/update-terms-and-conditions';
import { UpdateTermsAndConditions$Params } from '../fn/users/update-terms-and-conditions';
import { UpdateTermsAndConditionsResponse } from '../models/update-terms-and-conditions-response';
import { updateUserApplicationPreferences } from '../fn/users/update-user-application-preferences';
import { UpdateUserApplicationPreferences$Params } from '../fn/users/update-user-application-preferences';
import { UpdateUserApplicationPreferencesResponse } from '../models/update-user-application-preferences-response';
import { updateUserDetails } from '../fn/users/update-user-details';
import { UpdateUserDetails$Params } from '../fn/users/update-user-details';
import { UserAccessibleAppsResponse } from '../models/user-accessible-apps-response';
import { UserApplicationPreferencesResponse } from '../models/user-application-preferences-response';
import { userInfo } from '../fn/users/user-info';
import { UserInfo$Params } from '../fn/users/user-info';

@Injectable({ providedIn: 'root' })
export class ApiUsersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getUsers()` */
  static readonly GetUsersPath = '/api/v2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Response(params?: GetUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers(params?: GetUsers$Params, context?: HttpContext): Observable<void> {
    return this.getUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createUser()` */
  static readonly CreateUserPath = '/api/v2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser$Response(params: CreateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser(params: CreateUser$Params, context?: HttpContext): Observable<void> {
    return this.createUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getTermsAndConditions()` */
  static readonly GetTermsAndConditionsPath = '/api/v2/terms-and-conditions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTermsAndConditions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTermsAndConditions$Response(params?: GetTermsAndConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAllUsersTermsConditionsResponse>> {
    return getTermsAndConditions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTermsAndConditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTermsAndConditions(params?: GetTermsAndConditions$Params, context?: HttpContext): Observable<GetAllUsersTermsConditionsResponse> {
    return this.getTermsAndConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAllUsersTermsConditionsResponse>): GetAllUsersTermsConditionsResponse => r.body)
    );
  }

  /** Path part for operation `updateTermsAndConditions()` */
  static readonly UpdateTermsAndConditionsPath = '/api/v2/terms-and-conditions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTermsAndConditions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTermsAndConditions$Response(params: UpdateTermsAndConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTermsAndConditionsResponse>> {
    return updateTermsAndConditions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTermsAndConditions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTermsAndConditions(params: UpdateTermsAndConditions$Params, context?: HttpContext): Observable<UpdateTermsAndConditionsResponse> {
    return this.updateTermsAndConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateTermsAndConditionsResponse>): UpdateTermsAndConditionsResponse => r.body)
    );
  }

  /** Path part for operation `rejectInvite()` */
  static readonly RejectInvitePath = '/api/v2/rejectInvite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rejectInvite()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectInvite$Response(params: RejectInvite$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return rejectInvite(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rejectInvite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectInvite(params: RejectInvite$Params, context?: HttpContext): Observable<void> {
    return this.rejectInvite$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `inviteUser()` */
  static readonly InviteUserPath = '/api/v2/inviteUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inviteUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inviteUser$Response(params: InviteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return inviteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inviteUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inviteUser(params: InviteUser$Params, context?: HttpContext): Observable<void> {
    return this.inviteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `assignRole()` */
  static readonly AssignRolePath = '/api/v1/assignRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignRole$Response(params: AssignRole$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return assignRole(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignRole(params: AssignRole$Params, context?: HttpContext): Observable<void> {
    return this.assignRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAccountInfo()` */
  static readonly GetAccountInfoPath = '/api/v1/account-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountInfo$Response(params?: GetAccountInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getAccountInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountInfo(params?: GetAccountInfo$Params, context?: HttpContext): Observable<void> {
    return this.getAccountInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountInfo()` */
  static readonly AccountInfoPath = '/api/v1/account-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountInfo$Response(params: AccountInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return accountInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountInfo(params: AccountInfo$Params, context?: HttpContext): Observable<void> {
    return this.accountInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `acceptInvite()` */
  static readonly AcceptInvitePath = '/api/v2/acceptInvite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptInvite()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptInvite$Response(params: AcceptInvite$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return acceptInvite(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `acceptInvite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptInvite(params: AcceptInvite$Params, context?: HttpContext): Observable<void> {
    return this.acceptInvite$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteUser()` */
  static readonly DeleteUserPath = '/api/v2/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Response(params: DeleteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser(params: DeleteUser$Params, context?: HttpContext): Observable<void> {
    return this.deleteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateUserDetails()` */
  static readonly UpdateUserDetailsPath = '/api/v2/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserDetails$Response(params: UpdateUserDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateUserDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserDetails(params: UpdateUserDetails$Params, context?: HttpContext): Observable<void> {
    return this.updateUserDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `revokeInvite()` */
  static readonly RevokeInvitePath = '/api/v2/revokeInvite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revokeInvite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revokeInvite$Response(params: RevokeInvite$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return revokeInvite(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `revokeInvite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revokeInvite(params: RevokeInvite$Params, context?: HttpContext): Observable<void> {
    return this.revokeInvite$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `revokeAccess()` */
  static readonly RevokeAccessPath = '/api/v2/revokeAccess';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revokeAccess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revokeAccess$Response(params: RevokeAccess$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return revokeAccess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `revokeAccess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revokeAccess(params: RevokeAccess$Params, context?: HttpContext): Observable<void> {
    return this.revokeAccess$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `resendInvite()` */
  static readonly ResendInvitePath = '/api/v2/resendInvite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resendInvite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resendInvite$Response(params: ResendInvite$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resendInvite(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resendInvite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resendInvite(params: ResendInvite$Params, context?: HttpContext): Observable<void> {
    return this.resendInvite$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `changeRole()` */
  static readonly ChangeRolePath = '/api/v2/changeRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeRole$Response(params: ChangeRole$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changeRole(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeRole(params: ChangeRole$Params, context?: HttpContext): Observable<void> {
    return this.changeRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `roleCount()` */
  static readonly RoleCountPath = '/api/v2/role-count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  roleCount$Response(params?: RoleCount$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return roleCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roleCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  roleCount(params?: RoleCount$Params, context?: HttpContext): Observable<void> {
    return this.roleCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userInfo()` */
  static readonly UserInfoPath = '/api/v2/contactdetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  userInfo$Response(params?: UserInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userInfo(params?: UserInfo$Params, context?: HttpContext): Observable<void> {
    return this.userInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `changePassword()` */
  static readonly ChangePasswordPath = '/api/v2/changepassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword$Response(params: ChangePassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changePassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword(params: ChangePassword$Params, context?: HttpContext): Observable<void> {
    return this.changePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `addOwner()` */
  static readonly AddOwnerPath = '/api/v2/owner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOwner()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOwner$Response(params: AddOwner$Params, context?: HttpContext): Observable<StrictHttpResponse<AddOwnerResponse>> {
    return addOwner(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addOwner$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOwner(params: AddOwner$Params, context?: HttpContext): Observable<AddOwnerResponse> {
    return this.addOwner$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddOwnerResponse>): AddOwnerResponse => r.body)
    );
  }

  /** Path part for operation `getSessionAccountInfo()` */
  static readonly GetSessionAccountInfoPath = '/api/v2/session/account-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSessionAccountInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSessionAccountInfo$Response(params?: GetSessionAccountInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountInfoResponse>> {
    return getSessionAccountInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSessionAccountInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSessionAccountInfo(params?: GetSessionAccountInfo$Params, context?: HttpContext): Observable<AccountInfoResponse> {
    return this.getSessionAccountInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountInfoResponse>): AccountInfoResponse => r.body)
    );
  }

  /** Path part for operation `updateSessionAccountInfo()` */
  static readonly UpdateSessionAccountInfoPath = '/api/v2/session/account-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSessionAccountInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSessionAccountInfo$Response(params: UpdateSessionAccountInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateAccountInfoResponse>> {
    return updateSessionAccountInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSessionAccountInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSessionAccountInfo(params: UpdateSessionAccountInfo$Params, context?: HttpContext): Observable<UpdateAccountInfoResponse> {
    return this.updateSessionAccountInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateAccountInfoResponse>): UpdateAccountInfoResponse => r.body)
    );
  }

  /** Path part for operation `deleteSessionMembership()` */
  static readonly DeleteSessionMembershipPath = '/api/v1/session/membership';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSessionMembership()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSessionMembership$Response(params?: DeleteSessionMembership$Params, context?: HttpContext): Observable<StrictHttpResponse<LeaveOrganizationResponse>> {
    return deleteSessionMembership(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSessionMembership$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSessionMembership(params?: DeleteSessionMembership$Params, context?: HttpContext): Observable<LeaveOrganizationResponse> {
    return this.deleteSessionMembership$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeaveOrganizationResponse>): LeaveOrganizationResponse => r.body)
    );
  }

  /** Path part for operation `getUserAccessibleApps()` */
  static readonly GetUserAccessibleAppsPath = '/api/v2/apps/access';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAccessibleApps()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAccessibleApps$Response(params?: GetUserAccessibleApps$Params, context?: HttpContext): Observable<StrictHttpResponse<UserAccessibleAppsResponse>> {
    return getUserAccessibleApps(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserAccessibleApps$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAccessibleApps(params?: GetUserAccessibleApps$Params, context?: HttpContext): Observable<UserAccessibleAppsResponse> {
    return this.getUserAccessibleApps$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserAccessibleAppsResponse>): UserAccessibleAppsResponse => r.body)
    );
  }

  /** Path part for operation `getUserApplicationPreferences()` */
  static readonly GetUserApplicationPreferencesPath = '/api/v1/session/application-preferences';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserApplicationPreferences()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserApplicationPreferences$Response(params?: GetUserApplicationPreferences$Params, context?: HttpContext): Observable<StrictHttpResponse<UserApplicationPreferencesResponse>> {
    return getUserApplicationPreferences(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserApplicationPreferences$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserApplicationPreferences(params?: GetUserApplicationPreferences$Params, context?: HttpContext): Observable<UserApplicationPreferencesResponse> {
    return this.getUserApplicationPreferences$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserApplicationPreferencesResponse>): UserApplicationPreferencesResponse => r.body)
    );
  }

  /** Path part for operation `updateUserApplicationPreferences()` */
  static readonly UpdateUserApplicationPreferencesPath = '/api/v1/session/application-preferences';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserApplicationPreferences()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserApplicationPreferences$Response(params: UpdateUserApplicationPreferences$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateUserApplicationPreferencesResponse>> {
    return updateUserApplicationPreferences(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserApplicationPreferences$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserApplicationPreferences(params: UpdateUserApplicationPreferences$Params, context?: HttpContext): Observable<UpdateUserApplicationPreferencesResponse> {
    return this.updateUserApplicationPreferences$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateUserApplicationPreferencesResponse>): UpdateUserApplicationPreferencesResponse => r.body)
    );
  }

}
